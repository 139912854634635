import { promiseAll } from '@/utils/promiseAll';
import { CubeService } from '@/services/modules/cube.service';
import { BaseService } from '@/services/base.service';
import { personToSelectOption } from '@/utils/personToSelectOption';
import { companyToSelectOption } from '@/utils/companyToSelectOption';
import { exchangeFeeSegmentFees } from '@/utils/cm';
import { exchangeCurrency } from '@/utils/currency';
import cmApplicationTypes from '@/constants/cmApplicationTypes';

export const getDefaultState = () => ({ 
  contractLog: null,

  contractType: '',

  savedForLater: null,

  feeApproval: null,

  documentCategories: [],

  paymentCurrency: {
    iso: 'EUR',
    name: 'Euro',
  },

  myRegion: null,

  region: null,

  subRegionID: null,

  currentRegionID: null,

  officeDetails: {},

  loadingOfficeDetails: false,

  loadingRegion: false,

  regions: [],

  specializations: [],

  countries: [],

  languages: [],

  states: {},

  applicationChangeHistory: [],

  applicationTypes: cmApplicationTypes,

  franchiseeTypes: [
    { label: 'Franchisee is an individual', value: '1' },
    { label: 'Franchisee is a legal entity', value: '2' },
  ],

  shareholderTypes: [
    { label: 'Active Partner', value: '1' },
    { label: 'Silent Partner', value: '2' },
  ],

  history: {
    list: [],
    loading: false,
    totalRows: 0,
    currentPage: 1,
    totalPages: 1,
    rowsPerPage: 35,
    queryParams: {
      sort: '-changeDate',
    },
  },

  systemAccess: {
    ID: null,
    createdAt: null,
    modifiedAt: null,
    personFullDisplayName: null,
    personID: null,
    personName: null,
    relationID: null,
    relationRegionID: null,
    remaxTitle: null,
    roleSystemAccessTierID: null,
    systemAccessPackages: [],
    systemAccessRoleID: null,
    systemAccessRoleName: null,
    systemAccessTierID: null,
    uniquePersonID: null,
  },

  exchangeRates: [],
});

async function systemAccess({ commit }) {
  try {
    const user = JSON.parse(localStorage.userInfo);
    const systemAccessesRes = await BaseService.get('systemAccesses');
    const systemAccessesItem = (systemAccessesRes?.data?.result || []).filter((a) => a.personID === user?.personID)[0];
    const systemAccessRes = await BaseService.get(`systemAccess/${systemAccessesItem?.ID}`);
    const systemAccess = systemAccessRes?.data?.result || getDefaultState().systemAccess;
    commit('setState', { systemAccess });
    return systemAccess;
  } catch (e) {
    // alert(e.message);
  }
}

export async function init(store, { contract_id, authenticatedUserRegionID, loadedFromCreatePage }) {
  const { commit } = store;
  const regionStateHasValue = store.state.currentRegionID;

  let exchangeRates = [];
  let regions = [];
  let europeanCountries = [];
  let languages = [];
  let documentCategories = [];
  let phonecodeList = [];

  let _savedForLater: any;
  try {
    const applicationInfo = !contract_id ? null : await BaseService.get('v1/contractManagement/getFranchiseApplication', { applicationID: contract_id });
    
    if (!loadedFromCreatePage || !regionStateHasValue) {
      const exchangeRatesRes = await BaseService.get('newestCurrencyExchangeRates');
      exchangeRates = exchangeRatesRes?.data?.result || [];

      commit('setState', { exchangeRates });
      
      const res = await promiseAll([
        BaseService.get('regions', { sort: 'name' }),
        BaseService.get('v1/getAllEuropeanCountries'),
        BaseService.languages(),
        BaseService.get('v1/contractManagement/constants/contractDocumentCategories'),
        BaseService.phonecodes(),
        !contract_id ? null : contractLog(store, { applicationBufferID: contract_id, }),
        systemAccess(store),
        // authenticated user's region is the default region
        !authenticatedUserRegionID ? null : region(store, { regionID: authenticatedUserRegionID, subRegionID: null }),
      ]);
      
      regions = res[0]?.data?.result || [];
      europeanCountries = res[1]?.data?.data || [];
      languages = res[2]?.data?.result || [];
      documentCategories = res[3]?.data?.data || [];
      phonecodeList = res[4]?.data?.result || [];
    }

    _savedForLater = applicationInfo?.data?.data;

    let savedForLater = !_savedForLater
      ? null
      : {
          ..._savedForLater,
          application_data: JSON.parse(_savedForLater.applicationData),
          declined_reasons: JSON.parse(_savedForLater.applicationData),
        };

    // pass the submitted value from the payload to the savedForLater object
    const isSubmitted = applicationInfo?.data?.data?.submitted;

    // add isSubmitted to the savedForLater object, with value true if isSubmitted is 1, otherwise false
    // with initial sale/rental process, the isSubmitted is undefined (nothing from backend yet), 
    // if this is the case, isSubmitted is undefined, so if it is undefined, we set it to false
    if(isSubmitted) {
      savedForLater = savedForLater ? { ...savedForLater, isSubmitted: isSubmitted === 1 } : null;
    }  

    const feeApprovalRequestID = savedForLater?.application_data?.franchiseAgreement?.feeApprovalRequestID;
    let feeApproval = null;

    if (feeApprovalRequestID) {
      try {
        const feeApprovalRes = await BaseService.get('v1/contractManagement/feeApproval/getRequest', {
          feeApprovalRequestID,
        });
        feeApproval = feeApprovalRes?.data?.data;
      } catch (e) {
        /**/
      }
    }

    let applicationRegionId = savedForLater?.application_data?.regionID;
    let applicationSubRegionId = savedForLater?.application_data?.subRegionID;

    let currentStateRegionId = store.state.currentRegionID;
    let currentStateSubRegionId = store.state.region.subRegionID;
    
    if (applicationRegionId && (applicationRegionId != currentStateRegionId || applicationSubRegionId != currentStateSubRegionId)) {
      await region(store, {
        regionID: savedForLater?.application_data?.regionID,
        subRegionID: savedForLater?.application_data?.subRegionID,
      });
    }

    if (savedForLater?.application_data?.office.officeID)
      await officeDetails(store, savedForLater?.application_data?.office.officeID);

    const countries = (europeanCountries).map((c) => ({
      ...c,
      entryKey: c.iso,
      entryValue: c.name,
      value: c.iso,
      label: c.name,
    }));

    const phonecodes = (phonecodeList).filter((c) => countries.map((c) => c.entryKey).includes(c.entryKey));

    if (!loadedFromCreatePage || !regionStateHasValue) {
      commit('setState', {
        regions: regions,
        specializations: [
          {
            entryKey: 'Res',
            entryValue: '(RES) Residential',
            id: 2,
          },
          {
            entryKey: 'Col',
            entryValue: '(COL) Collections',
            id: 7,
          },
          {
            entryKey: 'Comm',
            entryValue: '(COMM) Commercial',
            id: 1,
          },
        ],
        countries,
        languages: languages,
        documentCategories: documentCategories,
        phonecodes: phonecodes.map((c) => {
          const country = countries.filter((cn) => cn.entryKey === c.entryKey)[0];
          return {
            value: c.entryKey,
            label: `(${c.entryKey}) ${country?.entryValue || c.entryValue}`,
          };
        }),
        feeApproval,
        savedForLater,
        applicationChangeHistory: (applicationInfo?.data?.data?.changeHistoryEntries || []).map((e) => {
          const metaData: any = [];
          const setMetaValue = () => {
            const metaValue = { key: e.field, old: e.oldValue, new: e.newValue };
            try {
              const old = JSON.parse(e.oldValue);
              const newV = JSON.parse(e.newValue);
              if (newV?.forEach) {
                newV.forEach((v) => {
                  Object.keys(v).forEach((key, i) => {
                    if (key === 'person') {
                      if (v[key]) v[key] = personToSelectOption(v[key])?.labelNoHTML;
                      if (old[i] && old[i][key]) old[i][key] = personToSelectOption(old[i][key])?.labelNoHTML;
                    }
                    if (key === 'company') {
                      if (v[key]) v[key] = companyToSelectOption(v[key])?.labelNoHTML;
                      if (old[i] && old[i][key]) old[i][key] = companyToSelectOption(old[i][key])?.labelNoHTML;
                    }
                    const ignore = v[key] && typeof v[key] === 'object';
                    if (!ignore && v[key] !== (old[i] && old[i][key])) {
                      metaData.push({ key, new: v[key], old: old[i] ? old[i][key] : null });
                    }
                  });
                });
              } else {
                metaData.push(metaValue);
              }
            } catch (e) {
              metaData.push(metaValue);
            }
          };
          if (e.oldValue || e.newValue) setMetaValue();
          return {
            ...e,
            changeDate: e.createdAt,
            humanChangeDate: e.createdAt,
            metaData: metaData.length ? JSON.stringify(metaData) : null,
          };
        }),
      });      
    } else {
      commit('setState', {
        feeApproval,
        savedForLater,
        applicationChangeHistory: (applicationInfo?.data?.data?.changeHistoryEntries || []).map((e) => {
          const metaData: any = [];
          const setMetaValue = () => {
            const metaValue = { key: e.field, old: e.oldValue, new: e.newValue };
            try {
              const old = JSON.parse(e.oldValue);
              const newV = JSON.parse(e.newValue);
              if (newV?.forEach) {
                newV.forEach((v) => {
                  Object.keys(v).forEach((key, i) => {
                    if (key === 'person') {
                      if (v[key]) v[key] = personToSelectOption(v[key])?.labelNoHTML;
                      if (old[i] && old[i][key]) old[i][key] = personToSelectOption(old[i][key])?.labelNoHTML;
                    }
                    if (key === 'company') {
                      if (v[key]) v[key] = companyToSelectOption(v[key])?.labelNoHTML;
                      if (old[i] && old[i][key]) old[i][key] = companyToSelectOption(old[i][key])?.labelNoHTML;
                    }
                    const ignore = v[key] && typeof v[key] === 'object';
                    if (!ignore && v[key] !== (old[i] && old[i][key])) {
                      metaData.push({ key, new: v[key], old: old[i] ? old[i][key] : null });
                    }
                  });
                });
              } else {
                metaData.push(metaValue);
              }
            } catch (e) {
              metaData.push(metaValue);
            }
          };
          if (e.oldValue || e.newValue) setMetaValue();
          return {
            ...e,
            changeDate: e.createdAt,
            humanChangeDate: e.createdAt,
            metaData: metaData.length ? JSON.stringify(metaData) : null,
          };
        }),
      });      
    }

    return JSON.parse(JSON.stringify(store.state));
  } catch (e) {
    throw e;
  }
}

export async function contractLog(store, opts: any = {}) {
  const { commit, state } = store;
  try {
    const id = opts?.applicationBufferID || opts?.contractLogID || state.contractLog?.id;
    const useApplicationBufferID = opts?.applicationBufferID ? 1 : undefined;
    const res = await promiseAll([
      !id ? null : BaseService.get('v1/contractManagement/contractLog/getInfo', { id, useApplicationBufferID, }),
    ]);

    const contractLog = res[0]?.data?.data?.id ? res[0]?.data?.data : null;

    commit('setState', { contractLog });
  } catch (e) {
    throw e;
  }
}

export async function region({ state, commit }, { regionID, subRegionID }) {
  try {
    // if (!regionID || state.loadingRegion) {
    if (!regionID) {
      // do nothing
      return null;
    } else {
      let regionPreApprovedOfficeNames: any;

      commit('setState', { loadingRegion: true });
      const subregionDropdown = await BaseService.get(`region/${regionID}/subregionDropdown`, { sort: 'entryValue' });
      const _subRegionID = subRegionID || (subregionDropdown?.data?.result || [])[0]?.entryKey || null;

      if (state.contractType === 'sale') {
        regionPreApprovedOfficeNames = await BaseService.get('v1/contractManagement/getAllPreApprovedNames', { regionID });
      }

      const [
        region,
        regionalPersons,
        regionCompanies,
        offices,
        franchiseAgreement,
        regionInfo,
        subRegionInfo,
        corporateNamesInRegion,
        listOfActiveMainOfficesInRegion,
        persons,
      ] = await promiseAll([
        BaseService.get(`region/${regionID}`, { subregionTagID: _subRegionID }),
        BaseService.get(`region/${regionID}/regionalPerson`, { only_active: '1' }),
        BaseService.get('companies', { 'filter[regionID][in]': regionID, sort: 'companyIsActive,name' }),
        BaseService.get('offices', {
          'filter[regionID][in]': regionID,
          'filter[officeIsActive][in]': [1,4,...(state.contractType === 'transfer' ? [2] : [])].join(','),
          sort: 'name',
        }),
        BaseService.get(`region/${regionID}/franchiseAgreement`, { subregionTagID: _subRegionID }),
        BaseService.get('v1/region/getInfo', { regionID, subregionTagID: _subRegionID }),
        _subRegionID ? BaseService.get('v1/subRegion/getInfo', { regionID, subRegionID: _subRegionID }) : null,
        BaseService.get('v1/entity/getAllCorporateNamesInRegion', { regionID, subregionTagID: _subRegionID }),
        BaseService.get('v1/office/getListOfActiveMainOfficesInRegion', { regionID }),
        // BaseService.get('persons', { sort: '-personIsActive,lastName', 'filter[regionID][in]': regionID, }),
      ]);

      const regionalFranchiseAgreement =
        subRegionInfo?.data?.data?.franchiseAgreement || regionInfo?.data?.data?.regionalFranchiseAgreement;
      if (regionalFranchiseAgreement) {
        regionalFranchiseAgreement.franchiseFeeStructures = regionalFranchiseAgreement.franchiseFeeStructures.map(
          (segment) => {
            return exchangeFeeSegmentFees(segment, {
              fromCurrency: regionalFranchiseAgreement.paymentCurrencyISO,
              toCurrency: regionalFranchiseAgreement.localFranchiseCurrencyISO,
              rates: state.exchangeRates,
            });
          }
        );
        if (regionalFranchiseAgreement.officeTransferFee) {
          regionalFranchiseAgreement.officeTransferFeeLocalCurrency = exchangeCurrency({
            amount: regionalFranchiseAgreement.officeTransferFee,
            fromCurrency: regionalFranchiseAgreement.paymentCurrencyISO,
            toCurrency: regionalFranchiseAgreement.localFranchiseCurrencyISO,
            rates: state.exchangeRates,
          }).amount;
        }
        if (regionalFranchiseAgreement.personAnnualDues) {
          regionalFranchiseAgreement.personAnnualDuesLocalCurrency = exchangeCurrency({
            amount: regionalFranchiseAgreement.personAnnualDues,
            fromCurrency: regionalFranchiseAgreement.paymentCurrencyISO,
            toCurrency: regionalFranchiseAgreement.localFranchiseCurrencyISO,
            rates: state.exchangeRates,
          }).amount;
        }
      }

      const regionState = {
        uniqueKey: Math.random().toString(36).substring(2),
        regionID,
        region: region?.data?.result || null,
        subRegionID: _subRegionID,
        subRegionInfo,
        regionInfo,
        subRegions: subregionDropdown?.data?.result || [],
        regionalPersons: regionalPersons?.data?.result || [],
        franchiseAgreement: franchiseAgreement?.data?.result || null,
        preApprovedOfficeNames: regionPreApprovedOfficeNames?.data?.data || [],
        companies: regionCompanies?.data?.result || [],
        offices: (offices?.data?.result || []).sort(
          (a, b) => (b.officeIsActive || b.status) - (a.officeIsActive || a.status)
        ),
        persons: persons?.data?.result || [],
        regionalFranchiseAgreement,
        corporateNamesInRegion: (corporateNamesInRegion?.data?.data || []).filter((n) => n),
        listOfActiveMainOfficesInRegion: listOfActiveMainOfficesInRegion?.data?.data || [],
      };
      commit('setState', {
        loadingRegion: false,
        currentRegionID: regionID,
        region: regionState,
        paymentCurrency: {
          iso: regionalFranchiseAgreement.paymentCurrencyISO,
          name: regionalFranchiseAgreement.paymentCurrencyISO,
        },
      });
      return regionState;
    }
  } catch (e) {
    commit('setState', { loadingRegion: false });
    throw e;
  }
}

export async function officeDetails({ state, commit }, officeID) {
  try {
    let officeDetails = null; // state.officeDetails[officeID];
    if (officeID && !state.loadingOfficeDetails && !officeDetails) {
      commit('setState', { loadingOfficeDetails: true });
      const [snaps, devScheduleSnapshotsRes, gciTrendByOffice, getNumberOfSalesAssociates, office] = await Promise.all([
        BaseService.get(`office/${officeID}/snapshots`, { sort: '-validFrom' }),
        BaseService.get(`office/${officeID}/developmentScheduleSnapshots`, { sort: '-valid_from' }),
        CubeService.getOfficeGciByYear(officeID, new Date().getFullYear() - 1),
        CubeService.getNumberOfSalesAssociates(officeID),
        BaseService.get('v1/office/getInfo', { officeID }),
      ]);

      const snap = (snaps?.data?.result || [])[0];
      let officeShareSnapshot = null;
      if (snap) {
        try {
          const officeShareSnap = await BaseService.get(`officeShareSnapshot/${snap.ID}`);
          officeShareSnapshot = officeShareSnap?.data?.result;
        } catch (e) {
          /**/
        }
      }

      const latestDevScheduleSnapshot = (devScheduleSnapshotsRes?.data?.result || [])[0];
      let devScheduleSnapshot = { officeDevelopmentSchedules: [] };
      if (latestDevScheduleSnapshot) {
        try {
          const devScheduleRes = await BaseService.get(
            `officeFranchiseAgreement/${latestDevScheduleSnapshot.ID}/developmentScheduleSnapshot`
          );
          devScheduleSnapshot = devScheduleRes?.data?.result;
        } catch (e) {
          /**/
        }
      }

	  const regionalFranchiseAgreement = state.region.regionalFranchiseAgreement;

      let previousYearGCILocalCurrency = ((gciTrendByOffice || [])[0] || {})['OfficePerformanceCube.gciForPeriod'];
      previousYearGCILocalCurrency = previousYearGCILocalCurrency ? Number(previousYearGCILocalCurrency) : null;

	  let previousYearGciEUR = ((gciTrendByOffice || [])[0] || {})['OfficePerformanceCube.gciForPeriodEur'];
      previousYearGciEUR = previousYearGciEUR ? Number(previousYearGciEUR) : null;

	  let previousYearGCI = previousYearGciEUR;
	  if (`${regionalFranchiseAgreement.paymentCurrencyISO}`.toUpperCase() !== 'EUR') {
		previousYearGCI = !previousYearGCI ? null : exchangeCurrency({
			amount: previousYearGciEUR,
			toCurrency: regionalFranchiseAgreement.paymentCurrencyISO,
			fromCurrency: 'EUR',
			rates: state.exchangeRates,
		}).amount;
	  }

      const numberOfSalesAssociates = Math.ceil(
        (getNumberOfSalesAssociates || []).reduce((acc, n) => {
          acc += Number(n['AgentCountCube.numberOfAgents'] || '0');
          return acc;
        }, 0) / (getNumberOfSalesAssociates || []).length
      );

      officeDetails = {
        ...state.officeDetails[officeID],
        transferEffectiveDate: snap?.validFrom || null,
        officeShares: officeShareSnapshot?.officeShares || [],
        devScheduleSnapshot,
        previousYearGCI,
        previousYearGCILocalCurrency,
        numberOfSalesAssociates: numberOfSalesAssociates ? Number(numberOfSalesAssociates) : null,
        office: office?.data?.data,
      };

      commit('setState', {
        loadingOfficeDetails: false,
        officeDetails: {
          ...state.officeDetails,
          [officeID]: officeDetails,
        },
      });
    }
    return officeDetails;
  } catch (e) {
    console.log(e);
    commit('setState', { loadingOfficeDetails: false });
  }
}

export async function regionCompanies({ state, commit }, regionID) {
  try {
    regionID = regionID || state.region?.regionID;
    const subregionTagID = state.region?.subRegionID;
    
    if (regionID && !state.loadingRegionCompanies) {
      commit('setState', { loadingRegionCompanies: true });

      const [regionCompanies, corporateNamesInRegion] = await promiseAll([
        BaseService.get('companies', { 'filter[regionID][in]': regionID, sort: 'companyIsActive,name' }),
        BaseService.get('v1/entity/getAllCorporateNamesInRegion', { regionID, subregionTagID, }),
      ]);

      const _corporateNamesInRegion = corporateNamesInRegion?.data?.data;

      commit('setState', {
        loadingRegionCompanies: false,
        region: {
          ...state.region,
          companies: regionCompanies?.data?.result || [],
          corporateNamesInRegion: (_corporateNamesInRegion ? _corporateNamesInRegion.filter((n) => n) : state.region.corporateNamesInRegion) || [],
        },
      });
    }
  } catch (e) {
    commit('setState', { loadingRegionCompanies: false });
  }
}

export async function preApprovedOfficeNames({ state, commit }, regionID) {
  try {
    if (regionID && !state.loadingPreApprovedOfficeNames) {
      commit('setState', { loadingPreApprovedOfficeNames: true });
      const [preApprovedOfficeNames] = await promiseAll([
        BaseService.get('v1/contractManagement/getAllPreApprovedNames', { regionID }),
      ]);
      commit('setState', {
        loadingPreApprovedOfficeNames: false,
        region: {
          ...state.region,
          preApprovedOfficeNames: preApprovedOfficeNames?.data?.data || [],
        },
      });
    }
  } catch (e) {
    commit('setState', { loadingPreApprovedOfficeNames: false });
  }
}
